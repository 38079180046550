import * as React from "react";

import { Header } from "./Header";
import { Footer } from "./Footer";
import { SEOTags } from "./SEOTags";
import { PageHeader } from "./PageHeader";

export const Bounded = ({ children }) => (
  <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
    {children}
  </div>
);

export const GrayInset = ({ children }) => (
  <div className="relative bg-gray-100 pb-20 lg:pb-28">
    <div className="absolute inset-0">
      <div className="bg-white h-1/3 sm:h-2/3"></div>
    </div>
    {children}
  </div>
);

export const Column = ({ children }) => (
  <div className="flex flex-col flex-grow">{children}</div>
);

export const Container = ({ children }) => (
  <div className="relative w-full mx-auto">{children}</div>
);

export const TightContent = ({ children }) => (
  <div className="relative w-full max-w-3xl mx-auto px-6">{children}</div>
);

export const Content = ({ children }) => (
  <div className="relative w-full max-w-screen-lg mx-auto px-6">{children}</div>
);

export const WideContent = ({ children }) => (
  <div className="relative w-full max-w-7xl mx-auto px-6">{children}</div>
);

export const SmallGrid = ({ children }) => (
  <div className="grid gap-5 sm:grid-cols-2">{children}</div>
);

export const Grid = ({ children }) => (
  <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-3">{children}</div>
);

export const Framed = ({ children }) => (
  <div className="sm:border sm:border-t-0 border-gray-100 flex">{children}</div>
);

export const Sidebar = ({ children }) => (
  <aside className="sm:border-l border-gray-100 bg-gray-100 col-span-2 sm:row-span-2 sm:col-span-1 ">
    {children}
  </aside>
);

export const Section = ({ children, theme = "light", shadow = true }) => (
  <div
    className={`w-full py-8 space-y-8 sm:py-12 sm:space-y-12 lg:py-20 lg:space-y-20  ${
      theme === "light"
        ? "bg-white"
        : `bg-gray-100 ${shadow ? "shadow-inner" : ""}`
    }
    
    `}
  >
    {children}
  </div>
);

export const Frame = ({ page, url, title, children }) => (
  <>
    <Header />
    <SEOTags page={page} url={url} title={title} />
    <main>
      <Container>
        <Framed>
          <Column>{children}</Column>
        </Framed>
      </Container>
    </main>
    <Footer />
  </>
);

export const BannerFrame = ({
  page,
  url,
  title,
  children,
  snippets,
  images,
}) => (
  <>
    <Header />
    <SEOTags page={page} url={url} title={title} />
    <PageHeader page={page} snippets={snippets} images={images} />
    <main>
      <Container>
        <Column>{children}</Column>
      </Container>
    </main>
    <Footer />
  </>
);
