import * as React from "react";
import {
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
} from "react-share";

export const Share = () =>
  typeof window !== `undefined` ? (
    <div className="mt-2 space-x-2 sm:space-x-4 flex">
      <TwitterShareButton url={document?.URL}>
        <svg
          style={{ fill: "#1DA1F2" }}
          className="h-8 w-8 sm:h-10 sm:w-10"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M10,0.4c-5.302,0-9.6,4.298-9.6,9.6s4.298,9.6,9.6,9.6s9.6-4.298,9.6-9.6S15.302,0.4,10,0.4z M13.905,8.264
	c0.004,0.082,0.005,0.164,0.005,0.244c0,2.5-1.901,5.381-5.379,5.381c-1.068,0-2.062-0.312-2.898-0.85
	c0.147,0.018,0.298,0.025,0.451,0.025c0.886,0,1.701-0.301,2.348-0.809c-0.827-0.016-1.525-0.562-1.766-1.312
	c0.115,0.021,0.233,0.033,0.355,0.033c0.172,0,0.34-0.023,0.498-0.066c-0.865-0.174-1.517-0.938-1.517-1.854V9.033
	C6.257,9.174,6.549,9.26,6.859,9.27C6.351,8.93,6.018,8.352,6.018,7.695c0-0.346,0.093-0.672,0.256-0.951
	c0.933,1.144,2.325,1.896,3.897,1.977c-0.033-0.139-0.049-0.283-0.049-0.432c0-1.043,0.846-1.891,1.891-1.891
	c0.543,0,1.035,0.23,1.38,0.598c0.431-0.086,0.835-0.242,1.2-0.459c-0.141,0.441-0.44,0.812-0.831,1.047
	c0.383-0.047,0.747-0.148,1.086-0.299C14.595,7.664,14.274,7.998,13.905,8.264z"
          />
        </svg>
      </TwitterShareButton>
      <LinkedinShareButton url={document?.URL}>
        <svg
          style={{ fill: "#0077b5" }}
          className="h-8 w-8 sm:h-10 sm:w-10"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M10,0.4c-5.302,0-9.6,4.298-9.6,9.6s4.298,9.6,9.6,9.6s9.6-4.298,9.6-9.6S15.302,0.4,10,0.4z M7.65,13.979
	H5.706V7.723H7.65V13.979z M6.666,6.955c-0.614,0-1.011-0.435-1.011-0.973c0-0.549,0.409-0.971,1.036-0.971s1.011,0.422,1.023,0.971
	C7.714,6.52,7.318,6.955,6.666,6.955z M14.75,13.979h-1.944v-3.467c0-0.807-0.282-1.355-0.985-1.355
	c-0.537,0-0.856,0.371-0.997,0.728c-0.052,0.127-0.065,0.307-0.065,0.486v3.607H8.814v-4.26c0-0.781-0.025-1.434-0.051-1.996h1.689
	l0.089,0.869h0.039c0.256-0.408,0.883-1.01,1.932-1.01c1.279,0,2.238,0.857,2.238,2.699V13.979z"
          />
        </svg>
      </LinkedinShareButton>
      <FacebookShareButton url={document?.URL}>
        <svg
          style={{ fill: "#4267B2" }}
          className="h-8 w-8 sm:h-10 sm:w-10"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M10,0.4c-5.302,0-9.6,4.298-9.6,9.6s4.298,9.6,9.6,9.6s9.6-4.298,9.6-9.6S15.302,0.4,10,0.4z M12.274,7.034
	h-1.443c-0.171,0-0.361,0.225-0.361,0.524V8.6h1.805l-0.273,1.486H10.47v4.461H8.767v-4.461H7.222V8.6h1.545V7.726
	c0-1.254,0.87-2.273,2.064-2.273h1.443V7.034z"
          />
        </svg>
      </FacebookShareButton>
    </div>
  ) : null;
