import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import striptags from "striptags";

import { Back } from "./Buttons";
import { Share } from "./Share";

export const Breadcrumbs = ({ url, list, title }) => (
  <>
    <Helmet>
      <script type="application/ld+json">
        {`{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "name": "Breadcrumbs",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "${list}",
        "item": "https://cbtn.org/${url}"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "${title}"
      }]
    }`}
      </script>
    </Helmet>
    <div className="flex justify-between items-center">
      <Back to={`/${url}`} label={list} />
      <Share />
    </div>
  </>
);

export const SEOTags = ({
  page,
  url,
  title = null,
  description = null,
  titleTemplate = null,
  twitterCard = "summary",
  lang = "en",
  meta = [],
}) => {
  const data = useStaticQuery(graphql`
    query SEOQuery {
      wagtail {
        page(slug: "cbtn") {
          ... on Wagtail_HomePage {
            title
          }
        }
      }
    }
  `);

  const metaDescription = description || page?.seoDescription || page?.short;
  const metaTitle = title || page?.seoTitle;
  const image = `${page?.image?.src?.replace(
    "https://d3b-684194535433-us-east-1-qa-cms.s3.amazonaws.com/original_images",
    "https://cbtn-s3.imgix.net"
  )}?w=1200`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || page?.title}
      titleTemplate={titleTemplate || `%s | ${data?.wagtail?.page?.title}`}
    >
      <meta name="description" content={metaDescription} />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link rel="canonical" href={`https://cbtn.org/${url}`} />
      <meta property="og:url" content={`https://cbtn.org/${url}`} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={striptags(metaDescription)} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={metaTitle} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:site" content="@CBTTC" />
      <meta name="twitter:description" content={striptags(metaDescription)} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={metaTitle} />
    </Helmet>
  );
};
